
export default {
  name: 'AtomButtonLink',
  props: {
    href: {
      required: false,
      type: String,
      default: 'javascript:void(0)'
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    icon: {
      required: false,
      type: String,
      default: ''
    },
    iconLeft: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
