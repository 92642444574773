import { render, staticRenderFns } from "./AtomButton.vue?vue&type=template&id=66f7d4f1&"
import script from "./AtomButton.vue?vue&type=script&lang=js&"
export * from "./AtomButton.vue?vue&type=script&lang=js&"
import style0 from "./AtomButton.vue?vue&type=style&index=0&id=66f7d4f1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/AtomButton.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fatoms%2Fcommon%2FAtomButton.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
